ul.page_meta {
  list-style: none;
  margin: 1em 0;
  padding: 0;
  display: inline;
  font-style: italic;

  li {
    display: inline;
    margin: 0;
    padding: 0;

    &.date:not(:last-child)::after {
      content: '|';
      margin: 0 0.2em;
    }

    &::before {
      display: none;
    }
  }
}

ul.post_tags {
  list-style: none;
  margin: 1em 0;
  padding: 0;
  font-style: italic;

  .title {
    margin-right: 0.2em;
  }

  li {
    display: inline;
    margin: 0;
    padding: 0;

    &::before {
      display: none;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;

  .btn {
    margin: 0 0.3em;
  }
}
